<template>
  <div>
    <section class="text-gray-700 xl:w-3/4 m-auto">
      <div class="sm:flex p-10">
        <img
          :src="require('@/assets/images/emojis/641.png')"
          class="m-auto sm:mx-0 w-24 h-24 sm:mr-10"
          alt="emoji"
        />
        <div class="sm:w-4/6">
          <h1 class="mb-2 font-bold text-center text-xl text-primary-normal">
            {{ $t("checkout.creditCard_success.thanks") }}
            <strong>{{ plan }} {{ recurrence }}</strong>
          </h1>
        </div>
      </div>
      <div class="text-center text-l sm:text-2xl md:w-2/3 m-auto">
        <p
          v-html="
            $t('checkout.creditCard_success.process', {
              plan: this.plan,
              recurrence: this.recurrence,
            })
          "
        ></p>
        <br />
        <div class="w-full text-center mt-10">
          <submit-button-default
            :text="$t('understood')"
            :showIcon="false"
            :fatButton="true"
            @click.native="next()"
            data-cy="checkout-success-btn"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import * as config from "../../constants";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { HOME_VIEW } from "@/router/constants";
import { mapGetters } from "vuex";
import { CHECKOUT_MODULE, GET_PLAN } from "@/store/constants/checkout";
export default {
  data() {
    return {
      recurrences: config.Recurrences,
      plans: config.Plans,
    };
  },
  components: {
    SubmitButtonDefault,
  },
  computed: {
    ...mapGetters({
      getChosenPlan: `${CHECKOUT_MODULE}/${GET_PLAN}`,
      isDesktop: `isDesktop`,
    }),
    plan() {
      return this.$t("plan_default_name")[this.getChosenPlan?.name];
    },
    recurrence() {
      return (
        config.Recurrences[this.getChosenPlan?.recurrencePeriod] ||
        "Sem recorrência"
      );
    },
  },
  methods: {
    async next() {
      if (this.isDesktop) {
        return alert("@@finalizarfluxoassinatura");
      }
      this.$router.push({
        name: HOME_VIEW,
      });
      location.reload();

      return true;
    },
  },
};
</script>

<style></style>
